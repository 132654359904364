<template>
	<div id="option">
		<div class="top">
			<div class="title">操作中心 <i></i> </div>
			<div class="infoCard">
				<div class="user">
					用户：
					{{userInfo.fullName}}
				</div>
				<div class="nums">
					<div class="num">
						<h3>我的会议</h3>
						<p>{{nums.meetingNum}} 场</p>
					</div>
					<p class="len"></p>
					<div class="num">
						<h3>我的项目</h3>
						<p>{{nums.projectNum}} 个</p>
					</div>
				</div>
			</div>
		</div>

		<div class="myList">
			<div class="option" @click="changePwd">
				<span>
					<img src="../../assets/imgs/icon_2.png">
					修改密码
				</span>
				<i class="el-icon-arrow-right"></i>
			</div>
			<div class="option" @click="createDoctorAccount">
				<span>
					<img src="../../assets/imgs/icon_2.png">
					创建医生账号
				</span>
				<i class="el-icon-arrow-right"></i>
			</div>
			<div class="option" @click="logOut()">
				<span>
					<img src="../../assets/imgs/icon_2.png">
					退出登录
				</span>
				<i class="el-icon-arrow-right"></i>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getMeetingNum,
		getAssistantInfo
	} from '@/request/api.js';
	export default {
		name: 'option',
		data() {
			return {
				nums: '',
				userInfo: ''
			}
		},
		created() {
			this.getUserInfo();
			this.getMeetingNumFun();
		},
		mounted() {},
		methods: {
			getMeetingNumFun() {
				getMeetingNum().then(res => {
					if (res.code == 200) {
						this.nums = res.data;
					}
				})
			},
			// 回显
			getUserInfo() {
				getAssistantInfo().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data;
					}
				})
			},

			changePwd() {
				this.$router.push({
					name: 'changepwd'
				})
			},

			createDoctorAccount() {
				this.$router.push({
					name: 'creatdoc'
				})
			},

			logOut() {
				let that = this;
				this.$confirm('此操作将退出当前账号的登录状态, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					that.$router.push({
						name: 'login'
					})
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	#option {
		padding: 20px;

		.top {
			padding: 20px;
			border-radius: 20px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			margin-bottom: 20px;

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.infoCard {
				display: flex;
				flex-direction: column;

				.user {
					font-size: 18px;
					margin: 20px 0;
				}

				.nums {
					display: flex;


					.num {
						flex: 1;
						padding: 10px 20px;
						border-left: 6px solid #3563E9;

						h3 {
							font-size: 18px;
							margin-bottom: 10px;
						}

						p {
							font-size: 16px;
						}
					}

					// .len {
					// 	width: 2px;
					// 	background-color: #f5f5f5;
					// }
				}
			}
		}

		.myList {
			display: flex;
			flex-direction: column;

			* {
				cursor: pointer;
			}

			.option {
				padding: 20px 30px;
				margin-bottom: 20px;
				border-radius: 10px;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 16px;



				span {
					display: flex;
					align-items: center;

					img {
						margin-right: 10px;
					}
				}

				i {
					font-size: 18px;
				}
			}
		}
	}
</style>